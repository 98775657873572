
import axios from 'axios';
const logsInsightsURL = process.env.REACT_APP_FUNCTIONURL;


const GetInsightsQuery = async() => {  

  try{
    const response = await axios.get(logsInsightsURL);
    return response.data;
  }
  catch(ex)
  {
    console.log(ex);
    return null;
  }

}

export { GetInsightsQuery };