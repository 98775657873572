import '@coreui/coreui/dist/css/coreui.min.css'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { CContainer, CRow, CCol, CHeader, CTable, CTableRow, CTableHead, CTableHeaderCell, CTableBody, CTableDataCell, CFormSelect, } from '@coreui/react'
import erpLogo from '../images/erp_logo_transparent.png'
import Sidebar from '../components/sidebar';
import Loading from '../components/Loading';
import '../components/styles/main.css'
import { GetInsightsQuery } from '../services/CloudWatchLogsService.mjs';
export default function Logs() {
  const [logs, setLogs] = useState([]);
  const [secondLogs, setSecondLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectOptions, setSelectOptions] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [secondlogsData, setsecondLogsData] = useState([]);
  const navigate = useNavigate();

  const isLogged = () =>
  {
    let storage = localStorage.getItem('token');
    console.log(storage)
    if(storage != null)
    {
      storage = JSON.parse(storage);
      if(storage?.expiry == null || storage?.token == null)
      {
        return false;
      }
      else if(new Date(storage?.expiry) > new Date() && storage?.token != null)
      {
        return true;
      }else{
        return false;  
      }
    }
  }

  useEffect(() => {
    /*
    if(!isLogged())
    {
      navigate('/login');
      return;
    }
      */
    GetInsightsQuery().then((data) => {
      console.log(data);
      if(data != logs && isLoading == true)
      {
        ProcessLogs(data);
        setIsLoading(false);
      }
    });
  }, []);

  const Logo = () =>
  {
    return <img src={erpLogo} height='30px'/>
  }  

  const ProcessLogs = (data) =>
  {
    if(data != null)
    {      
      let id = 1;
      const items = data.map((log) =>
      {
        let date = new Date(log[0].value);
        return {
          id: id++,
          company: log[2].value.split('/')[2],
          user: `${log[2].value.split('/')[3]}`,
          message: log[1].value.split("-"),
          date: date
        }
      });
  
      items.sort((a, b) => {
        if(a.date > b.date) { return -1; }
        if(a.date < b.date) { return 1; }
        if(a.company < b.company) { return -1; }
        if(a.company > b.company) { return 1; }
        return 0;
      });  

      
      
      setLogsData(items.filter((log) => log.date > new Date(Date.now() - 60*60*24*1000)));
      setLogs(items.filter((log) => log.date > new Date(Date.now() - 60*60*24*1000)));      
      setsecondLogsData(items);
      setSecondLogs(items);
      BuildFilters(items);
    }
  }

  const LogCard = (props) =>
  {
    const cards = props.data.map((item) => 
      <CTableRow key={"logCard"+item.id}>
        <CTableHeaderCell scope="row">{item.id}</CTableHeaderCell>
        <CTableDataCell>{item.company}</CTableDataCell>
        <CTableDataCell>{item.date.toLocaleString()}</CTableDataCell>
        <CTableDataCell>{item.user}</CTableDataCell>
        <CTableDataCell>{item.message}</CTableDataCell>          
      </CTableRow>     
    )     
      
    return (
      <div>
        <CTable striped hover>
        <CTableHead>
        <CTableRow>
          <CTableHeaderCell scope="col">#</CTableHeaderCell>
          <CTableHeaderCell scope="col">Company</CTableHeaderCell>
          <CTableHeaderCell scope="col">DateTime</CTableHeaderCell>
          <CTableHeaderCell scope="col">User</CTableHeaderCell>
          <CTableHeaderCell scope="col">Message</CTableHeaderCell>
        </CTableRow>
      </CTableHead>
      <CTableBody>
        {cards}
      </CTableBody>
      </CTable>
    </div>
  )}

  const BuildFilters = (data) =>
  { 
    let options = [];
    options.push('All');
    data.forEach((log) => {
      let company = log.company;
      if(!options.includes(company))
      {
        options.push(company);
      }
    });
    setSelectOptions(options);  
  }

  const HandleFilterChange = (data) =>
  {
    const thisOption = data.target.value;
    if(thisOption == "All")
    {
      setLogs(logsData);
      setSecondLogs(secondlogsData);
    }
    else
    {
      const filteredLogs = logsData.filter((log) => log.company == thisOption);
      setLogs(filteredLogs.filter((log) => log.date > new Date(Date.now() - 60*60*24*1000)));
      setSecondLogs(secondlogsData.filter((log) => log.company == thisOption));
    }
  }

  return (
    <CContainer fluid={true}>
      <CRow>
        <Sidebar/>
        <CCol> {/* Content */}          
          <CRow> {/* Header */}
            <CHeader className='mb-3' container="fluid">
              <CContainer>
                <h1 className='text'>Last 24 Hours</h1>
              </CContainer>
            </CHeader>            
          </CRow>
          {isLoading ? <Loading /> :
          <div>
          <CRow>
            <span>
            <CFormSelect label="Filter by Company" onChange={(e) => HandleFilterChange(e)} style={{width:'30%', margin:'1vw', display:"inline"}}  options={selectOptions} >
            </CFormSelect>
            </span>
          </CRow>
          <CRow > {/* Content */}
            <CCol>               
              <LogCard data={logs}>
              </LogCard>              
            </CCol>
          </CRow>       
          </div>
          }
          <CRow> {/* Header */}
            <CHeader className='mb-3' container="fluid">
              <CContainer>
                <h1 className='text'>Last 72 Hours</h1>
              </CContainer>
            </CHeader>            
          </CRow>
          {isLoading ? <Loading /> :
          <div>
          <CRow > {/* Content */}
            <CCol>               
              <LogCard data={secondLogs}>
              </LogCard>              
            </CCol>
          </CRow>       
          </div>
          }
        </CCol>
      </CRow>      
    </CContainer>
  )
}